import React from 'react';
// import structureHead from '../../assets/structureHead.svg'
import sturcture from '../../assets/structureRec.svg'
import sturctureFlow from '../../assets/AuctionStructures.svg'
const AcutionStructure = () => {
  return (
    <div >
    
     {/* <img src={sturcture} alt="" className="responsive-image" />  */}
     <img src={sturctureFlow} alt="" className="responsive-image" /> 
    </div>
  );
};

export default AcutionStructure;
