import ApiService from '../apiService';
const querystring = require('querystring')

var AuctionService = {

    findAll: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "auctions"
            $options.method = "get"

            if ($data) {
                $options.url = "auctions?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },

    getAutionToLive: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getAutionToLive"
            $options.method = "get"

            if ($data) {
                $options.url = "getAutionToLive?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },

    getAuctionView: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getAuctionView"
            $options.method = "get"

            if ($data) {
                $options.url = "getAuctionView?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getActionExistData: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getActionExistData"
            $options.method = "get"

            if ($data) {
                $options.url = "getActionExistData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getAuctionDateLocation: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getAuctionDateLocation"
            $options.method = "get"

            if ($data) {
                $options.url = "getAuctionDateLocation?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },

    getAuctionallData: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getauctionallData"
            $options.method = "get"

            if ($data) {
                $options.url = "getauctionallData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    
    getAuctionUser: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getAuctionUser"
            $options.method = "get"

            if ($data) {
                $options.url = "getAuctionUser?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getActiveLiveList: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "auctionLiveList"
            $options.method = "get"

            if ($data) {
                $options.url = "auctionLiveList?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },

    getActionCardData: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getActionCardData"
            $options.method = "get"

            if ($data) {
                $options.url = "getActionCardData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getNextAuctionTime: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getNextAuctionTime"
            $options.method = "get"

            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getRecentlyAdded: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getRecentlyAdded"
            $options.method = "get"

            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getVehicleListing: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getVehicleListing"
            $options.method = "get"

            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },

    findOne: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "auctions/" + $data.id
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },

    update: function ($data) {
        return new Promise(function (resolve, reject) {
            console.log($data, "w")
            let $options = {};
            $options.data = $data
            $options.url = "auctions/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },

    create: function ($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "auctions";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    delete: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "deleteAuction/" + $data.id;
            $options.method = "post"

            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },

};
export default AuctionService;