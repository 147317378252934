import { Col, Row, ConfigProvider } from 'antd';
import AppRouter from './router/router.js'
import { BrowserRouter as Router } from 'react-router-dom';
// import Header from './components/HeaderComponent/header.jsx'
// import Footer from '../src/components/Footercomponent/Footer.jsx';
import React, { useEffect, useState } from 'react';
import { Layout, Flex } from 'antd';
//import SideMenu from '../src/Components/SideMenu/SideMenu.js';

const { Sider, Content } = Layout;

const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#ffffff',
};

const siderStyle = {
    // textAlign: 'start',
    // lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
    height: '100%'
};
const App = () => {
    const [pathName, setPathName] = useState(window.location.pathname);
    const [userType, setUserType] = useState('');

    useEffect(() => {
        let user = localStorage.getItem('USER_DETAILS') && JSON.parse(localStorage.getItem('USER_DETAILS')).user_type;
        user && setUserType(user)
    }, [])




    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#364ea2',
                },
                components: {
                    Segmented: {
                        itemSelectedBg: '#364ea2',
                        itemSelectedColor: '#ffffff',
                        // itemHoverBg:''
                    },
                }
            }}
        >
            <div>
                <Row>
                    <Col span={24}>
                        <Router>
                           
                            <AppRouter />
                        </Router>

                    </Col>
                </Row>
            </div>
        </ConfigProvider>
    )
};

export default App;
