import React from 'react'
import NextAuction from './mainc/Next_Auction'
import Recentlyadded from './mainc/Recently_added'
import Todayauctions from './mainc/Today_auctions'
import BecomeMember from './mainc/BecomeMember'
import SmallGlimbs from './mainc/SmallGlimbs'
import HowAuctionWorks from './mainc/HowAuctionWorks'

const MainContent = () => {
  return (
    <div className='w-main'>
      <Recentlyadded/>
      <NextAuction/>
      <Todayauctions/>
      <HowAuctionWorks/>
      <BecomeMember/>
      <SmallGlimbs/>
    </div>
  )
}

export default MainContent