import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Aboutus from '../Website/AboutUs/Index.jsx'
import Auctionflow from '../Website/AuctionFlow/Index.jsx';
import AcutionStructure from '../Website/AuctionSturcture/Index.jsx'
import WhyTvs from '../Website/WhyTvs/Index.jsx'
import Login from '../components/Login/Login';
import OurServices from '../Website/OurServices/index.jsx'
import ContactUs from '../Website/ContactUs/Index.jsx'
import Faq from '../Website/components/Faq.jsx/index.jsx'
import Buy from '../Website/Buy/index.jsx'
const AppRouter = () => {
    return (
        <Routes>
             <Route path="/" exact element={<Login />} />
            <Route path="/whytvs" element={< WhyTvs />} />
            <Route path="/auctionstructure" element={< AcutionStructure />} />
            <Route path="/auctionflow" element={< Auctionflow />} />
            <Route path="/aboutus" element={< Aboutus />} />
            <Route path="/ourservices" element={< OurServices />} />
            <Route path="/Contactus" element={<ContactUs />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/buy" element={<Buy />} />
        

        </Routes>
    );
};

export default AppRouter;