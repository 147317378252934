import React from "react";
import { Image } from "antd";
import step1 from '../../assets/step1.svg'
import step2 from '../../assets/step2.svg'
import { Link } from "react-router-dom";
import step3 from '../../assets/step3.svg'
import "./HowAuctionWorks.css"
const HowAuctionWorks = () => {
  return (
    <div className="howworks-container">
      <div className="content-label">How auction works</div>
      <div className="step-container">
        <div className="step1">
          <div className="image-part">
            <Image
              className="card-image"
              height={280}
              src={step1}
            />
          </div>

          <div className="part-detail">
            <div className="part-label">Auction Structure</div>
            <div className="part-note">
              See how the auction is structured and who can participate!!!
            </div>
            <Link className="part-link" to="/auctionstructure">Know More</Link>
          </div>
        </div>
        <div className="step1">
          <div className="image-part">
            <Image
              className="card-image"
              height={280}
              src={step2}
            />
          </div>
          <div className="part-detail">
            <div className="part-label">Auction Flow</div>
            <div className="part-note">
            Understand the basics of getting membership and start bidding
            </div>
            <Link className="part-link" to="/auctionflow">Know More</Link>
          </div>
        </div>
        <div className="step1">
          <div className="image-part">
            <Image
              className="card-image"
              height={280}
              src={step3}
            />
          </div>
          <div className="part-detail">
            <div className="part-label">How to Start ?</div>
            <div className="part-note">
            Transparent Live auction for both Buyer and seller. With Value added service like warranty and more to
            </div>
            <Link className="part-link" to="/whytvs">Know More</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowAuctionWorks;
