import React from 'react'
import HeaderContent from '../components/headerContent.jsx'
import WhoWeAre from './components/WhoWeAre.jsx'
import WhatWeDo from './components/WhatWeDo.jsx'
import OurVision from './components/OurVision.jsx'
import FooterContent from '../components/footerContent.jsx'
import "../website.css";
import aboutUsbg from '../assets/aboutUsbg.svg'

const Aboutus = () => {
  const label = "";
  const title = "Revolutionizing Used Car Sales";
  const matter = "";
  const button = false;
  const bgImage = aboutUsbg;
  const bgColor = "#4C00D9";

  return (
    <div className="container">
      <HeaderContent
        label={label}
        title={title}
        matter={matter}
        button={button}
        bgImage={bgImage}
        bgColor={bgColor}
      />
    <WhoWeAre></WhoWeAre>
    
    <WhatWeDo></WhatWeDo>
    <OurVision></OurVision>
    <FooterContent></FooterContent>
    </div>
  )
}

export default Aboutus