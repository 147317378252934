import React, { useState } from "react";
import { Carousel } from "antd";
import TopMenu from "./topmenu";
import HeaderComponent from "./headerComponent";

const HeaderContent = ({
  label,
  title,
  matter,
  button,
  carouselData,
  bgImage,
  bgColor,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const onChange = (currentSlide) => {
    setCurrentIndex(currentSlide);
  };

  const currentData = carouselData ? carouselData[currentIndex] : {};
  const headerStyle = {
    backgroundColor: carouselData ? currentData.bgColor : bgColor,
    backgroundImage: carouselData
      ? `url(${currentData.bgImage})`
      : `url(${bgImage})`,
    backgroundSize: "cover",

    backgroundPosition: "center",
  };

  return (
    <div className="w-header-container" style={headerStyle}>
      <div >
        <TopMenu />
        </div>
        <div className="w-header-container-inner">
        {carouselData ? (
          <Carousel autoplay afterChange={onChange} >
            {carouselData.map((data, index) => (
              <div key={index}>
                <HeaderComponent
                  label={data.label}
                  title={data.title}
                  matter={data.matter}
                  button={data.button}
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <HeaderComponent
            label={label}
            title={title}
            matter={matter}
            button={button}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderContent;
