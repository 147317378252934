import React from "react";
import { Link } from "react-router-dom";

const BecomeMember = () => {
  return (
    <div className="become-member">
      <div className="bocome-container">
        <div className="bocome-content">
          Seamless and efficient experience for dealers,
          <br />
          making it easier for them to
          <br />
          connect and transact to develop their business.
        </div>
       <Link to='https://tvscertified.in/apps/addsignup' target="blank"> <div className="bomome-button">Become a Member</div></Link>
      </div>
    </div>
  );
};

export default BecomeMember;
