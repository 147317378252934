import React, { useState, useEffect } from "react";
import { Select, Avatar, Menu, Dropdown } from "antd";
import headLogowhite from "../assets/certified-whilte2.svg";
import headLogoBlue from "../assets/certified-blue.svg";
import { UserOutlined, CaretDownFilled, MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./topMenu.css";

const TopMenu = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleScroll = () => {
      let heightThreshold;

      if (window.innerWidth <= 768) {
        // Mobile devices
        heightThreshold = window.innerHeight * 0.10;
      } else {
        // Desktop or larger devices
        heightThreshold = window.innerHeight * 0.30;
      }

      if (window.scrollY > heightThreshold) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/buy">Buy</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/ourservices">Our Services</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="/aboutus">About Us</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to="https://tvscertified.in/apps/login" target="blank">
          <div className="userSection">
            <Avatar icon={<UserOutlined />} className="avatar" />
            <div>
              <div className="signin">Sign in Account</div>
            </div>
            <CaretDownFilled className="caretIcon" />
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={`topMenu ${scrolled ? "scrolled" : ""}`}>
      <div className="topleft">
      {isMobile && (
          <div className="mobile-menu">
            <Dropdown overlay={menu} trigger={['click']}>
              <MenuOutlined className="mobile-menu-icon" />
            </Dropdown>
          </div>
        )}
        <Link to="/">
          <img src={scrolled ? headLogoBlue : headLogowhite} alt="logo" className="logo" />
        </Link>
        <Select
          placeholder="Set Location"
          className="locationSelect"
          defaultValue="Madhavaram"
          options={[
            { value: "Madhavaram", label: "Madhavaram-Chennai" },
          ]}
        />
      </div>
      <div className={`topright${scrolled ? "scrolled" : ""}`}>
        {!isMobile && (
          <>
            <Link className="topanchor" to="/buy">Buy</Link>
            <Link className="topanchor" to="/ourservices">Our Services</Link>
            <Link className="topanchor" to="/aboutus">About Us</Link>
            <Link to='https://tvscertified.in/apps/login' target="blank" className={`link${scrolled ? "scrolled" : ""}`}>
              <div className="userSection">
                <Avatar icon={<UserOutlined />} className="avatar" />
                <div>
                  <div className="signin">Sign in Account</div>
                </div>
                <CaretDownFilled className="caretIcon" />
              </div>
            </Link>
          </>
        )}
   
      </div>
    </div>
  );
};

export default TopMenu;
