import React from "react";
import step3 from "../../assets/step3.svg";
import "./AboutUs.css"

const WhatWeDo = () => {
  return (
    <div className="whyTvsCertified-container">
      <div className="content-label">What we do ?</div>
        <div className="WhatDoText" >
        TVS Certified is the leading B2B auction platform, which provides the best price discovery through transparent Vehicle Auction. 
          We add Value to both Buyers and Sellers to improve their business proposition.
        </div>
      </div>
    
  );
};

export default WhatWeDo;
