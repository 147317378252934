import React, { useEffect } from "react";
import { HeartTwoTone } from "@ant-design/icons";
import { Image } from "antd";
import { Link } from "react-router-dom";
// import "../../Website/components/mainc/VehicleListingWebcard.css"
const WebCard = ({recentData,showButton}) => {
  
  useEffect(()=>{
    console.log(recentData,'keycarsdwu77eeuj')
  },[recentData])
  return (
    <>
      {recentData && recentData.length>0 && recentData.map((key,i)=>(
<>
      <div className="card-component" key={i}>
      {key&& key.image_url && key?.image_url.frontview[0] ? ( <div className="image-container">
          <Image
            className="card-image"
            height={180}
            src={key?.image_url ?key?.image_url.frontview[0]:''}
          />
        </div>):(
        <p style={{padding:'60px'}}>No image available</p>
        )}
        <div className={`detail-box ${!showButton ? "no-button-box" : ""}`}>
          <div className="detail-box-inner">
            <div className="card-title">{key?.make_code}-{key?.model_code}</div>
            {/* <div className="card-details">61K km . Diesel . Manual </div>
            */}
          </div>
          {showButton && <div className="card-button">
           <Link to='https://tvscertified.in/apps/login' target="blank"> <p class="btn-label">View Details</p></Link>
            </div>}
        </div>
      </div>
      </>
    ))}
    </>
  );
};

export default WebCard;
