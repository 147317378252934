import React from "react";
// import step3 from "../../assets/step3.svg";
import './faq.css';

const Faq = () => {
  return (
    <div className="whyTvsCertified-container">
      {/* <div className="content-label">Frequently asked questions ?</div>
      <div className="content-label">What does TVS Certified do ?</div>

        <div style={{"fontSize":"24px","font-weight": "400","color": "#6C757D","lineHeight":"42px","marginBottom":"20px"}}>
        TVS Certified is a Vehicle Auction platform where dealers can Buy or sell car through our TVS Certified Portal or Mobile Ap.
        </div> */}
        <h2 className="Header">Frequently asked questions?</h2>
    <div className="faq-question">
      <h3>1. What does TVS Certified do?</h3>
      <p>TVS Certified is a Vehicle Auction platform where dealers can Buy or Sell car through our TVS Certified Portal or Mobile App.</p>
    </div>
    <div className="faq-question">
      <h3>2. Is this a B2C Platform?</h3>
      <p>No it is a B2B Platform and is not accessible to individual customer</p>
    </div>
    <div className="faq-question">
      <h3>3. How can I participate in the auction?</h3>
      <p>You have to pay a refundable membership fee along with a subscription fee to participate in the auction.</p>
    </div>
    <div className="faq-question">
      <h3>4. Should I come to the yard on the Auction day?</h3>
      <p>No. You don't have to be physically available in the yard. You can bid from anywhere through our TVS Certified mobile app</p>
    </div>
    <div className="faq-question">
      <h3>5. I cannot participate in the Auction day but still want to bid?</h3>
      <p>Don't worry. We have a pre-bid option where you can bid until 2 hours before the Auction Event. Our Automated Bot will bid on your behalf.</p>
    </div>
    <div className="faq-question">
      <h3>6. Is it easy to bid through the app?</h3>
      <p>Yes it is very easy. All you have to do is to press the + button to bid for the vehicle.</p>
    </div>
      </div>
    
  );
};

export default Faq;
