import { Col, Row, Card, Form, Input, Button } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import headerLogo from "../../assests/tvsMobility.png";
import "./Login.css";
import { useState } from "react";
import AddSignUp from "../Login/AddSignUp";
import UserService from "../../api/service/Auction/AuctionService";
import { useNavigate } from "react-router-dom";
import BgcImage from "../../assests/loginBgc.svg";
import thumbUp from "../../assests/thumbWeb.svg";
import Index from '../../Website/Index'
let error = null;

const Login = ({ handleSubmit }) => {
  const [userform] = Form.useForm();
  const [showDialog, setShowDialog] = useState(false);
  const [showError, setErrorData] = useState("");
  const [showIcon, setIcon] = useState(false);
  const navigate = useNavigate();

  function handlebutton() {
navigate('/addsignup')
    setShowDialog(true);
  }
  function handleSubmit(value) {
    console.log(value);
    UserService.findAll(value)
      .then((res) => {
        console.log(res, "hhhggggs");
        if (res && res.data && res.data.data) {
          let authData = res.data.data[0];
          setErrorData(authData.error);
          console.log(authData, "error");
          localStorage.setItem("TOKEN", authData.token);
          localStorage.setItem(
            "USER_DETAILS",
            JSON.stringify(authData.userData[0])
          );
          navigate("/");
          window.location.reload();
        }
        // if (res && !res?.data?.data){
        //     setErrorData('Invalid User')
        // }
        else {
          console.log("Invalid");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  return (
    <>
    {false ?
      <Row className="content1">
        <Col
          span={12}
          style={{
            backgroundImage: `url(${BgcImage})`,
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10vh",
            padding: "12vh 0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "6vh",
            }}
          >
            <img
              className="content-image"
              src={
                "https://mobility-snaps.s3.ap-south-1.amazonaws.com/public/loginLogo.png"
              }
              alt="TVS Logo"
            />
            <div className="content-italic">a brand you can trust</div>
          </div>

          <div className="content-normal">
            Transparent Live auction for both Buyer and seller. With Value added
            service like warranty and more to serve
          </div>
          <img className="content-thumb" src={thumbUp} alt="Thumb Logo" />
        </Col>
        <Col span={12} align={"center"} className="login-card-center">
          <Col xs={24} sm={20} md={18} lg={16} xl={13}>
            <img className="Login-Header-Img" src={headerLogo} alt="TVS Logo" />
            <Card className="Login-Card">
              <div className="Login-title">Login</div>
              <Form
                form={userform}
                name="userform"
                className="form-control"
                onFinish={handleSubmit}
                layout="vertical"
              >
                <Row style={{ textAlign: "start" }}>
                  <Col span={24}>
                    <div className="Label">Email or Mobile</div>
                    <Form.Item
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Email",
                        },
                        {
                          type: "email",
                          message: "The input is not a valid email address!",
                        },
                      ]}
                    >
                      <Input
                        className="customsearchbar"
                        placeholder="Email or Mobile"
                        onChange={() => setErrorData("")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <div className="Label">Password</div>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Password",
                        },
                      ]}
                    >
                      <Input.Password
                        className="customsearchbar"
                        placeholder="Password"
                        onChange={() => setErrorData("")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Button
                  style={{ marginTop: 10, backgroundColor: "#70b333" }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Login
                </Button>
                <Row style={{ marginTop: 5, textAlign: "center" }}>
                  <Col>
                    <div style={{ textAlign: "center", color: "red" }}>
                      {showError}
                    </div>
                  </Col>
                </Row>

                <Row justify={"space-between"} style={{ marginTop: 5 }}>
                  <Col>
                    <div className="forget"  onClick={()=>{navigate('/forgetpassword')}}>Forgot Password</div>
                  </Col>
                  <Col>
                    <Button
                      type="text"
                      style={{ color: "#70b333" }}
                      onClick={() => {
                        handlebutton();
                      }}
                    >
                      Sign Up
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Col>
      </Row>:<Index></Index>

    }
      {/* <AddSignUp
        showDialog={showDialog}
        closeDialog={(value) => {
          setShowDialog(value);
        }}
      /> */}
    </>
  );
};
export default Login;
