import React from 'react';
//import './HowToStart.css';
import "./WhyTvs.css"
import HowoStart from '../assets/WhyTvss.svg'
import AllSet from '../assets/allSet.svg'
const HowToStart = () => {
  return (
    <>
    <div style={{display:'flex',justifyContent:'center'}}>
     <img src={HowoStart} alt="" className="responsive-image" />
     
    </div>
    <div>
      <img className='Image-WhyTvs' src={AllSet} alt="" />
    </div>
    </>
  );
};

export default HowToStart;
