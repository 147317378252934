import React from "react";
import step3 from "../../assets/step3.svg";
import "./content.css"
const Content = () => {
  return (
    <>
    <div className="whyTvsCertified-container">
      <div className="content-label">TVS Certified Private Limited</div>
      <div className="content-wrapper">
        <div className="content-block">
          <div className="content-title">Registered Office</div>
          <div>No/13 , Bypass Road, Poonamallee,</div>
          <div>Chennai - 600056</div>
          <div>info@tvscertified.in</div>
        </div>
        <div className="content-block">
          <div className="content-title">Yard</div>
          <div>No/117, GNT Road,Ring Road, Madhavaram,</div>
          <div>Chennai - 600110</div>
        </div>
        <div className="content-block">
          <div className="content-title">Membership Enquiry</div>
          <div>support@tvscertified.in</div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Content;
