import React from 'react'
import HeaderContent from '../../components/headerContent.jsx'
import FaqContent from './Components.jsx/FaqConten.jsx'
import FooterContent from '../../components/footerContent.jsx'
// import "../website.css";
import faqbg from '../../assets/faqbg.svg'

const Faq = () => {
  const label = "";
  const title = "Have Question! Get clarified";
  const matter = "";
  const button = false;
  const bgImage = faqbg;
  const bgColor = "#1B72D9";

  return (
    <div className="container">
      <HeaderContent
        label={label}
        title={title}
        matter={matter}
        button={button}
        bgImage={bgImage}
        bgColor={bgColor}
      />
    {/* <WhoWeAre></WhoWeAre> */}
    
    {/* <WhatWeDo></WhatWeDo> */}
    <FaqContent></FaqContent>
    <FooterContent></FooterContent>
    </div>
  )
}

export default Faq