import React, { useState, useRef } from 'react';
import "./VideoPlayer.css"
import {PlayCircleOutlined} from '@ant-design/icons'
import video from '../../assets/certified-explainer-video.mp4'
const VideoPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        className="video"
        src={video}
        type="video/mp4"
        onClick={handlePlayPause}
      />
      {!isPlaying && (
        <div className="play-button" onClick={handlePlayPause}>
          <PlayCircleOutlined />
        </div>
      )}
     {!isPlaying && <div className="caption" >Have time! Watch the Video to know more about us</div>}
    </div>
  );
};

export default VideoPlayer;
