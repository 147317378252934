import React from "react";
import HeaderContent from "../components/headerContent.jsx";
import HowToStart from "./HowToStart.jsx";
import FooterContent from "../components/footerContent.jsx";
import "../website.css";
import whyTvsBg from '../assets/whyTvsBg.svg'
const WhyTvs = () => {
  const label = "";
  const title = "Join Now for Exclusive Access and Exciting Bidding Opportunities!";
  const matter = "";
  const button = false;
  const bgImage = whyTvsBg;
  const bgColor = "#30302F";

  return (
    <div className="container">
      <HeaderContent
        label={label}
        title={title}
        matter={matter}
        button={button}
        bgImage={bgImage}
        bgColor={bgColor}
      />
      <HowToStart />
      <FooterContent />
    </div>
  );
};

export default WhyTvs;
