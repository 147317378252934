import React from "react";
import TopMenu from "../components/topmenu.jsx";
import BuyCar from "./components/BuyCar.jsx"
import FooterContent from "../components/footerContent.jsx";
import HeaderContent from "../components/headerContent.jsx";
import auctionVehicle from '../assets/autionVehicle.svg'

import "../website.css";

const Buy = () => {
  const label = "";
  const title = "Diverse Vehicle Selection";
  const matter = "";
  const button = false;
  const bgImage = auctionVehicle;
  const bgColor = "#383739";
  return (
    <div className="container">
      <HeaderContent
        label={label}
        title={title}
        matter={matter}
        button={button}
        bgImage={bgImage}
        bgColor={bgColor}
      />
    <div>
    <TopMenu></TopMenu>
      </div>
  <div style={{marginTop:"60px"}}> 
  <BuyCar></BuyCar> 
  </div>
     
    
     <FooterContent></FooterContent>
    
    </div>
   
  
  );
};

export default Buy;
