import React, { useEffect, useRef, useState } from "react";
import WebCard from "../WebCard";
import { Button, Col, Row } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import AuctionService from '../../../api/service/Auction/AuctionService';
// import "./recentlyAdded.css"; // Make sure this CSS file is imported

const Recentlyadded = () => {
  let currentPage = useRef(1);
  let pageSize = 4;

  let startIndex = (currentPage.current - 1) * pageSize;

  const [data, settData] = useState([]);
  const [recentData, setRecenttData] = useState([]);

  useEffect(() => {
    getRecentData();
  }, []);

  const getRecentData = async () => {
    try {
      let res = await AuctionService.getRecentlyAdded();
      console.log(res, 'raw response');

      res.forEach(e => {
        try {
          e.image_url = JSON.parse(e.image_url);
        } catch (error) {
          console.error(`Failed to parse image_url for item with id ${e.id}:`, error);
          e.image_url = null; // Or handle in another appropriate way
        }
      });

      console.log(res, 'parsed response');
      res.sort((a, b) => b.id - a.id);
      console.log(res, 'sorted by id in descending order');
      
      let result = res.slice(startIndex, startIndex + pageSize);
      console.log(result, 'paged result');

      setRecenttData(res);
      settData(result);
    } catch (error) {
      console.error('Failed to fetch recent data:', error);
    }
  };

  const handlePrev = () => {
    if (currentPage.current > 1) {
      currentPage.current -= 1;
    } else {
      currentPage.current = 1;
    }
    let startIndex = (currentPage.current - 1) * pageSize;
    let result = recentData.slice(startIndex, startIndex + pageSize);
    settData(result);
  };

  const handleNext = () => {
    let maxPage = Math.ceil(recentData.length / pageSize);
    if (currentPage.current < maxPage) {
      currentPage.current += 1;
    } else {
      currentPage.current = 1;
    }
    let startIndex = (currentPage.current - 1) * pageSize;
    let result = recentData.slice(startIndex, startIndex + pageSize);
    settData(result);
  };
console.log(data,'dat333')
  return (
    <div className="recent-container">
      <Row gutter={13} style={{ padding: 10, display: 'flex', alignItems: 'center' }}>
        <Col span={10}>
          <span className="content-label">Recently added</span>
        </Col>
        <Col offset={8} span={6} style={{display:'flex',alignItems:'center'}} className="btn-Prev">
          <span style={{ padding: 5 }}>
            <Button onClick={handlePrev} style={{ height: 32, width: 32, borderRadius: '50%' }}>
              <LeftOutlined />
            </Button>
          </span>
          <Button onClick={handleNext} style={{ height: 32, width: 32, borderRadius: '50%' }}>
            <RightOutlined />
          </Button>
        </Col>
      </Row>

      {data && data.length > 0 && (
        <div className="cards-grid">
          {data.map((item, index) => (
            <div key={index} className="card-item">
              <WebCard recentData={[item]} showButton={false} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Recentlyadded;