import React from 'react'
import headLogo from '../assets/headLogo.svg'
import { Link } from "react-router-dom";

const FooterContent = ({member}) => {
  return (
    <>
    <div className='w-footer'>
      <div className="f1">
       <div > <Link to ="/"><img src={headLogo} alt="logo" /></Link>
        <div className="f-caption" style={{marginTop:"10px"}} >
        Registered Office<br /> No/13 , Bypass Road, Poonamallee,  <br /> Chennai - 600056 <br /> info@tvscertified.in
        </div>
        </div>
     
      <div  className={`f2 ${!member ? "f2-nomember" : ""}`}>
        <div className="f2-left">
         
          <div className="f2-lists">
          <div className="f2-label">Main Links</div>
          <ul className="f-caption">
            

            <li className='f2-item'> <Link className="f-caption" to="/">Home</Link></li>
            <li className='f2-item'> <Link className="f-caption"  to="/aboutus">About Us</Link></li>
            <li className='f2-item'> <Link className="f-caption" to="/Contactus">Contact Us</Link> </li>
          </ul>
          </div>
         
          <div className="f2-lists">
          <div className="f2-label">Useful links</div>
          <ul className="f-caption">
            <li className='f2-item'> <Link className="f-caption" to="/ourservices">Our Services</Link> </li>
          </ul>
          </div>
         
          <div className="f2-lists">
          <div className="f2-label">Member links</div>
          <ul className="f-caption">
            <li className='f2-item'> <Link className="f-caption" to="https://tvscertified.in/apps/addsignup" target='blank'>Become a Member</Link></li>
            <li className='f2-item'> <Link className="f-caption" to="/faq">FAQ</Link></li>
          </ul>
          </div>
          
        </div>
      { member && <div className="f2-right">
          <div className="f2-label">Newsletter</div>

        </div>}
      </div>
      </div>
      <div className="f3">
        <div className="f-caption">© 2024 - TVS Certified. All rights reserved.</div>
      </div>
    </div>
    </>
  )
}

export default FooterContent