import React from "react"
import ServiceContent from "./servicecontent"
import serviceimg from "../assets/orangeline.png"
import ServiceCard from "./servicecard"
import "./service.css"
const OurServiceScreen = () => {
    return(
        <>
        {/* <div className="service-container">
            <div className="service-container-inner">
                <ServiceContent/>
            </div>

        </div> */}
        <div className="backgrount-service-container">
        <div className="rectangle-service">
            <img src ={serviceimg} className="innerimg"></img>
        </div>
        <div className="service-detail-header"> We offer a  wide range</div>
        <div className="t-service-header-detail">  services but just not limited to</div>
        <ServiceCard/>
        </div>
        </>
    )
}

export default OurServiceScreen