import React from "react";
import { Link } from "react-router-dom";
import "./AboutUs.css"

const WhoWeAre = () => {
  return (
    <div className="whyTvsCertified-container">
      <div className="content-label">Who we are ?</div>
      <div className="WhatDoText" >TVS Certified Private Limited is the joint venture between TVS Mobility, global automobile corporate dealer and India’s largest distributor specializing in developing and supplying market-leading automobile solutions, Mitsubishi Corporation, a global integrated business enterprise that develops and operates businesses together with its global network of around 1,800 group companies, and Arai Shoji, one of the largest Used vehicle Auction company in Japan.</div>
      <div className="step-container">
        <div className="who-we-are">
          <div className="we-are-details">
            <Link className="part-label" to ="https://www.tvs.in" target="blank">TVS Mobility</Link>
           
          </div>
        </div>
        <div className="who-we-are">
          <div className="we-are-details">
            <Link className="part-label" to ="https://www.mitsubishicorp.com/jp/en/index.html" target="blank" >Mitsubishi Corporation</Link>
           
          </div>
        </div>
        <div className="who-we-are">
          <div className="we-are-details">
            <Link className="part-label" to ="https://www.arai-group.co.jp/en/" target="blank">Arai Shoji</Link>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
