import React from "react";
import HeaderContent from "../components/headerContent.jsx";
import AuctionFlow from "./components/AuctionFlow.jsx";
import WhyTvsCertified from "./components/WhyTvsCertified.jsx";
import FooterContent from "../components/footerContent.jsx";
import "../website.css";
import auctionflowBg from '../assets/auctionflowBg.svg'

const Auctionflow = () => {
  const label = "";
  const title =
    "Understand how Auction works ?";
  const matter = "";
  const button = false;
  const bgImage = auctionflowBg;
  const bgColor = "#1B72D9";
  return (
    <div className="container">
      <HeaderContent
        label={label}
        title={title}
        matter={matter}
        button={button}
        bgImage={bgImage}
        bgColor={bgColor}
      />
      <AuctionFlow></AuctionFlow>
      {/* <WhyTvsCertified></WhyTvsCertified> */}
      <FooterContent></FooterContent>
    </div>
  );
};

export default Auctionflow;
