import React from "react"
import card1 from "../assets/clogo1.svg"
import card2 from "../assets/clog2.svg"
import card3 from "../assets/clog3.svg"
import card4 from "../assets/clog4.svg"
import card5 from "../assets/clog5.svg"
import card6 from "../assets/clog6.svg"
import card7 from "../assets/clog7.svg"

const ServiceCard = () => {
    return (
        <>
            <div className="card-container">
                <div className="inner-card">
                    <div className="logo-details">
                    <img src= {card1} className="cardlogo"></img> 
                    <span className="card-tittle"> Warranty and AMC</span>
                    </div>
                </div>
                <div className="inner-card">
                    <div className="logo-details">
                    <img src= {card2} className="cardlogo"></img> 
                    <span className="card-tittle">Road Side Assistance</span>
                    </div>
                </div>
                
            </div>
            <div className="card-container">
                <div className="inner-card">
                    <div className="logo-details">
                    <img src= {card3} className="cardlogo"></img> 
                    <span className="card-tittle">Refurbishment</span>
                    </div>
                </div>
                <div className="inner-card">
                    <div className="logo-details">
                    <img src= {card4} className="cardlogo"></img> 
                    <span className="card-tittle">GPS and Vehicle performance Tracker</span>
                    </div>
                </div>
                
            </div>
            <div className="card-container">
                <div className="inner-card">
                    <div className="logo-details">
                    <img src= {card5} className="cardlogo"></img> 
                    <span className="card-tittle">Finance</span>
                    </div>
                </div>
                <div className="inner-card">
                    <div className="logo-details">
                    <img src= {card6} className="cardlogo"></img> 
                    <span className="card-tittle">Insurance</span>
                    </div>
                </div>
                
            </div>
            <div className="card-container">
                <div className="inner-card">
                    <div className="logo-details">
                    <img src= {card7} className="cardlogo"></img> 
                    <span className="card-tittle"> Accessories</span>
                    </div>
                </div>  
            </div>
        </>
    );
};

export default ServiceCard;
