import React from "react";
import "./AboutUs.css"
const OurVision = () => {
  return (
    <>
    <div className="our-vision">
      
    <div className="content-label">Our Vision</div>
    </div>
    <div className="vision-container">
      <div className="vison-text">
      Our endeavour is to run the robust used vehicle auctions not by<br/> competing but by complementing.
      </div>
    </div>
    </>
  );
};

export default OurVision;
