import React, { useEffect, useRef, useState } from "react";
import WebCard1 from "./WebCard1";
// import { Pagination } from "antd";
import { Button, Col, Pagination, Row } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import AuctionService from '../../../api/service/Auction/AuctionService';

const Todayauctions = () => {
  const cardsData = Array.from({ length: 10 }, (_, index) => ({
    id: index + 1,
    title: `Card ${index + 1}`,
  }));

  // const [currentPage, setCurrentPage] = useState(1);
  let currentPage = useRef(1);
  let pageSize = 12;

  let startIndex = (currentPage.current - 1) * pageSize;

  const [data, settData] = useState([]);
  const [recentData, setRecenttData] = useState([]);
  const handlePageChange = (page) => {
    // setCurrentPage(page);
  };
  useEffect(() => {
    getRecentData()
  }, [])

  const getRecentData = async () => {

    let res = await AuctionService.getVehicleListing();
    res.forEach(e => e.d.image_url = JSON.parse(e.d.image_url))
    let result = res.slice(startIndex, startIndex + pageSize);
    setRecenttData(res)
    settData(result)

    console.log(data, 'recentres123datata')
  }
  const handlePrev = () => {
    if (currentPage.current > 1) {
      currentPage.current -= 1;
    }
    else {
      currentPage.current = 1;
    }
    let pageSize = 12;

    let startIndex = (currentPage.current - 1) * pageSize;
    let res = JSON.parse(JSON.stringify(recentData))
    let result = res.slice(startIndex, startIndex + pageSize);
    settData(result)
  }
  const handleNext = () => {
    let maxPage = recentData.length/12
    if (currentPage.current <= maxPage) {
      currentPage.current += 1;
    }
    else {
      currentPage.current = 1;
    }
    let pageSize = 12;

    let startIndex = (currentPage.current - 1) * pageSize;
    let res = JSON.parse(JSON.stringify(recentData))
    let result = res.slice(startIndex, startIndex + pageSize);
    settData(result)
  }
  const itemRender = (_, type, originalElement) => {
    // alert(type)
    if (type === 'prev') {
      // alert('prev')
      //  return <Button style={{height:32,width:32,borderRadius:'50%'}}><LeftOutlined /></Button>
    }
    if (type === 'next') {
      // alert('next')
      // return <Button style={{height:32,width:32,borderRadius:'50%'}}><RightOutlined /></Button>;
    }
    return null;
  };

  return (
    <>
     
        <Row gutter={13} className="Buycar-style" >
          <Col span={24}>
            <span className="content-label" style={{fontWeight:700}}>Auction Vehicles</span>
          </Col>
        </Row>

        <Row gutter={24} style={{marginLeft:0,marginRight:0}}>
          <Col span={24}>
          {data && data.length > 0 && <div >
          <WebCard1 recentData={data} showButton={true}/>
        </div>}
          </Col>
          
        </Row>

       <Row gutter={[24,24]} style={{padding:"20px"}}>
       <Col  span={24} style={{ textAlign: 'end' }}>
            <span style={{ padding: 25 }}><Button type="primary" onClick={handlePrev}>Prev</Button></span>
            <Button type="primary" onClick={handleNext} >Next</Button>
          </Col>
       </Row>
      
    </>
  );
};

export default Todayauctions;
