
const apiConfig = {
        //"baseUrl":"http://localhost:8036",
        // "baseUrl":"http://localhost:8036",
        "fondendUrl":"https://tvscertified.in",
        "baseUrl":"https://tvscertified.in:8036",
        "gatWayUrl":"https://79a4z7n2g5.execute-api.ap-south-1.amazonaws.com/dev",
        "apiKey": "apiKey",
        "secrect": "secrect"
 }
 
 export default apiConfig;
