import { Button, Checkbox, Col, Divider, Form, Input, Modal, Row,Space, Segmented, Select, Steps, Switch, message, theme, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import "./Login.css"
import CustomerService from "../../api/service/Auction/AuctionService";
import UserService from "../../api/service/Auction/AuctionService";
import {EditOutlined} from '@ant-design/icons';
import { useSelector } from "react-redux";
import BgcImage from "../../assests/loginBgc.svg";
import { useNavigate } from "react-router";

const { Option } = Select;


const AddSignUp = (props) => {
  const location_id = useSelector((state) => state.branchReducer.branch_id);
  const formRef = React.useRef(null);
  const formRef2 = React.useRef(null);
  const formRef3 = React.useRef(null);
  const formRef4 = React.useRef(null);
  const [showVerify, setShowVerify] = useState(false);
  const [closeModal, setCloseModal] = useState(props.showDialog);
  const [editDissabled, setDissabled] = useState(false);
  // const [data, showData] = useState(false);
  const [current, setCurrent] = useState(0);
  const [userType, setUserType] = useState('buyer');
  const [combinedValues,setCombinedValues] = useState({});
  const [contactDataReady, setContactDataReady] = useState(false);
  const [checkbox,setCheckbox] =  useState(false);
  const [permanentAddress,setPermanentAddress] = useState({});
  const [editCompany,setEditCompany] = useState(true);
  const [showPAN, setShowPAN] = useState(true);
  const [showGST, setShowGST] = useState(true);
  const [showCIN, setShowCIN] = useState(true);
  const [paninitalstate,setpaninitialstate] = useState(false);
  const [cininitalstate,setcininitialstate] = useState(false);
  const [form] = Form.useForm();
  const [country,setCountry] = useState('');
  const [state,setState] = useState('');
  const navigate = useNavigate();
  const segmentedOptions = [
    { value: 'buyer', label: 'I Am a Buyer' },
    { value: 'seller', label: 'I Am a Seller' },
  ];

  useEffect(() => {
    setCloseModal(props.showDialog)
    if (current === 0){
      console.log(combinedValues.document)
      formRef?.current?.setFieldsValue(combinedValues.document)
    }
    if (current === 1) {
      formRef2?.current?.setFieldsValue(combinedValues.details)
      // formRef2.current.setFieldsValue(obj);
      // setPermanentAddress(obj);
      // setState(obj.state);
      // setCountry(obj.country);
    }
    // if (current === 2) {
    //   formRef3.current.setFieldsValue(obj);
    //   setPermanentAddress(obj);
    // }

    if (current === 2) {
      if (checkbox === true) {
        const obj = {
          communication_address1: combinedValues.details.address1,
          communication_address2: combinedValues.details.address2,
          communication_address3: combinedValues.details.address3,
          communication_country: combinedValues.details.country,
          communication_state: combinedValues.details.state,
          communication_city: combinedValues.details.city,
          communication_zip: combinedValues.details.zip,
        };
        formRef3.current.setFieldsValue(obj);
      } else if (checkbox === false) {
        formRef3.current.resetFields();
      }
    }

    if (current === 3) {
      formRef4?.current?.setFieldsValue(combinedValues.contact)
      // let obj = {
      //   // first_name: "Bala",
      //   // middle_name: "G",
      //   // last_name: "Ganesh",
      //   // name: "Moorthy",
      //   // email: "moorthy@tibura.com",
      //   // mobile: '9897723783'
      // }
      // formRef4.current.setFieldsValue(obj);
    }

    if (checkbox){
      if (checkbox === true) {
        const obj = {
          communication_address1: combinedValues.details.address1,
          communication_address2: combinedValues.details.address2,
          communication_address3: combinedValues.details.address3,
          communication_country: combinedValues.details.country,
          communication_state: combinedValues.details.state,
          communication_city: combinedValues.details.city,
          communication_zip: combinedValues.details.zip,
        };
        formRef3?.current?.setFieldsValue(obj);
      } else if (checkbox === false) {
        formRef3.current.resetFields();
      }
    }

  }, [current,props,checkbox])

  const steps = [
    {
      title: 'Registration',
      //   content: 'First-content',
    },
    {
      title: 'Details',
      //   content: 'Second-content',
    },
    {
      title: 'Address For Communication',
      //   content: 'Last-content',
    },
    {
      title: 'Contact Person',
      //   content: 'Last-content',
    },
  ];

  // useEffect(()=>{
  //   alert("00000000")
  //         formRef?.current?.resetFields();
  //         formRef2?.current?.resetFields();
  //         formRef3?.current?.resetFields();
  //         formRef4?.current?.resetFields();
  // },[])


  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  function handleOk(value) {
    console.log("ok", value)
  }

  function handleCancel() {
    setCurrent(0)
    // props.closeDialog(false)
    setCloseModal(false)
    formRef && formRef?.current?.resetFields();
    formRef2 && formRef2?.current?.resetFields();
    formRef3 && formRef3?.current?.resetFields();
    formRef4 && formRef4?.current?.resetFields();
  }


  function handleAddress(value) {
    setCombinedValues(prevState => ({...prevState, details: value}));
    setCurrent(current + 1)
  }

  function handleSecondAddress(value) {  
    formRef2?.current?.setFieldsValue({
      email:value.email
    });
    setCombinedValues(prevState => ({...prevState, second_address: value}));
    setCurrent(current + 1)
  }

  function handleContact(value) {
    setCombinedValues((prevState) => ({ ...prevState, contact: value }));
    setContactDataReady(true);
    // setCloseModal(false)
  }
  
  useEffect(() => {
    if (contactDataReady && combinedValues) {
      combinedValues.contact.location_id = location_id;
      console.log("Submitting data:", combinedValues);
      // props.closeDialog(false)
      // setCurrent(0)
       CustomerService.addCustomer(combinedValues)
        .then((res) => {
          console.log(res);
          // props.closeDialog(true)
          message.success('Processing complete!');
          setCloseModal(false)
          setContactDataReady(false);
          navigate('/login')
          // formRef?.current?.resetFields();
          // formRef2?.current?.resetFields();
          // formRef3?.current?.resetFields();
          // formRef4?.current?.resetFields();
        })
              .catch((e) => {
                console.log(e);
              })

            //   .finally(() => {
            //     // Reset the flag after submitting data
                // setContactDataReady(false);
            //     message.success('Processing complete!');
            //     props.closeDialog(false)
            //     setCloseModal(false);
            //     formRef && formRef?.current?.resetFields();
            //     formRef2 && formRef2?.current?.resetFields();
            //     formRef3 && formRef3?.current?.resetFields();
            //     formRef4 && formRef4?.current?.resetFields();
              // });
              // props.closeDialog(true)
    }
  }, [contactDataReady, combinedValues]);
  
  

  const handleFirstData = async (value) => {
    console.log(value,'value');
    setCombinedValues(prevState => ({...prevState, document: value}));
    setCurrent(current + 1);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleUdyogAadhaar = (event) => {
    let value = event.target.value;
    if (value.length > 0) {
      setShowPAN(true);
      setShowGST(false);
      setShowCIN(false);
      setpaninitialstate(true)
      setcininitialstate(false)
    } else {
      setShowPAN(true);
      setShowGST(true);
      setShowCIN(true);
      setpaninitialstate(false)
      setcininitialstate(false)
    }
  };

  const handlePan = (event) => {
    let value = event.target.value;
    if (value.length === 10) {
      setShowGST(true);
      setShowPAN(true);
      setShowCIN(false);
    } else {
      setShowGST(false);
      setShowPAN(true);
      setShowCIN(true);
    }
  };

  const handleGst = (event) => {
    let value = event.target.value;
    if (value.length > 0) {
      setShowCIN(true);
      setShowGST(true);
      setShowPAN(false);
      setcininitialstate(true)
      setpaninitialstate(true)
      // handleUdyogAadhaar(false);
    } else {
      setShowCIN(false);
      setShowGST(true);
      setShowPAN(true);
      setcininitialstate(false)
      setpaninitialstate(false)
    }
  };

  // const handleGst = (event) => {
  //   let value = event.target.value;
  //   value.length >= 8 ? setShowVerify(true) : setShowVerify(false)
  //   // setDissabled(true)
  // }

  // const handlePan = (event) => {
  //   let value = event.target.value;
  //   value.length === 10 ? setShowVerify(true) : setShowVerify(false)
  //   // setDissabled(true)
  // }

  const handleCin = ($event) => {
    let value = $event.target.value;
    value.length >= 8 ? setShowVerify(true) : setShowVerify(false)
    // setDissabled(true)
  }

  const handleUsertype = (value) => {
    // formRef.current.setFieldsValue('user_type',user_type)
    // console.log("valueeee",formRef.current)
    setUserType(value)
    // setDissabled(true)
  }
  
  const setAdress = (value) => {
    if (value === false) {
      const obj = {
        communication_address1: permanentAddress.address1,
        communication_address2: permanentAddress.address2,
        communication_address3: permanentAddress.address3,
        communication_country: permanentAddress.country,
        communication_state: permanentAddress.state,
        communication_city: permanentAddress.city,
        communication_zip: permanentAddress.zip,
      };
      formRef3.current.setFieldsValue(obj);
      // console.log('values', permanentAddress);
      // perform other actions with permanentAddress
    } else if (value === true) {
      formRef3.current.resetFields();
    }
  };

  function handleCompany($event){
     let value = $event.target.value;
     formRef2.current.setFieldsValue({
      legal_name: value,
      trade_name: value
     })
  }
  const divStyle = {
    backgroundImage: `url(${BgcImage})`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    height: '100vh',
    width: '100vw' 
  };


  return (
      // <Modal centered title="Signup" open={closeModal}  maskClosable={false} onOk={handleOk} onCancel={handleCancel} footer={false} width={'75%'} >
      <div className="container" style={divStyle} >
        <div className="aline">
      <div className="page-content-wrap">
                <div className="page-whitebox-wrap">
                    <div className="page-data-wrap">
                        {/* <div className="data-form"> */}
        {/* <Steps current={current} items={items} style={{ paddingTop: 15 }} /> */}
       
        <div style={{marginTop:20}}>
        <div className="card">
        <div style={{ padding: 10 }}>
        <Steps current={current} items={items} style={{ paddingTop: 15}} />


          {current === 0 && (
            <div style={{ padding: 15 }}>
              <Form name="basic" style={{ paddingTop: 10 }} initialValues={{ remember: true, }} onFinish={handleFirstData}
                onFinishFailed={onFinishFailed} autoComplete="off" layout="vertical" ref={formRef}>
  
  <Row gutter={16}>
          {showGST && (
            <Col span={8}>
              <Form.Item
                name="gst_num"
                label={<div className="label">GST</div>}
                rules={[
                  { required: true, message: "GST Is Required" },
                  {
                    validator: async (_, value) => {
                      if (value && value.length > 3) {
                        const res = await CustomerService.findAll();
                        let filter =
                          res && res?.filter((item) => item.gst_num === value);
                        if (filter && filter.length > 0) {
                          throw new Error("GST Number Already Exists!");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input
                  type="text"
                  showReadOnly={false}
                  placeholder="GST"
                  size="large"
                  onChange={handleGst}
                />
              </Form.Item>
            </Col>
          )}
          {showPAN && (
            <Col span={8}>
              <Form.Item
                name="udyog_aadhaar"
                label={<div className="label">Udyog Aadhaar</div>}
                rules={[
                  { required: true, message: "Udyog Aadhaar Is Required" },
                  {
                    validator: async (_, value) => {
                      if (value && value.length > 3) {
                        const res = await CustomerService.findAll();
                        let filter =
                          res &&
                          res?.filter((item) => item.udyog_aadhaar === value);
                        if (filter && filter.length > 0) {
                          throw new Error(
                            "Udyog Aadhaar Number Already Exists!"
                          );
                        }
                      }
                    },
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder="Udyog Aadhaar"
                  showReadOnly={false}
                  onChange={handleUdyogAadhaar}
                />
              </Form.Item>
            </Col>
          )}

          {showGST && cininitalstate &&(
            <Col span={8}>
              <Form.Item
                name="cin_num"
                label={<div className="label">CIN</div>}
                rules={[
                  { required: true, message: "CIN Is Required" },
                  {
                    validator: async (_, value) => {
                      if (value && value.length > 3) {
                        const res = await CustomerService.findAll();
                        let filter =
                          res && res?.filter((item) => item.cin_num === value);
                        if (filter && filter.length > 0) {
                          throw new Error("CIN Number Already Exists!");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input
                  type="text"
                  showReadOnly={false}
                  placeholder="CIN"
                  size="large"
                  onChange={handleCin}
                />
              </Form.Item>
            </Col>
          )}
          {(showPAN || showCIN) && (paninitalstate) && (
            <Col span={8}>
              <Form.Item
                name="pan_num"
                label={<div className="label">PAN</div>}
                rules={[
                  { required: true, message: "PAN  Is Required" },
                  {
                    validator: async (_, value) => {
                      if (value && value.length > 3) {
                        const res = await CustomerService.findAll();
                        let filter =
                          res && res?.filter((item) => item.pan_num === value);
                        if (filter && filter.length > 0) {
                          throw new Error("PAN Number Already Exists!");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input
                  type="text"
                  showReadOnly={false}
                  placeholder="PAN"
                  size="large"
                  // onChange={handlePan}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
                <Col span={24} style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', paddingTop: 30 }}>
                    <Form.Item >
                      <Row>
                        <Button size='middle' style={{marginRight:5}} onClick={() => {navigate('/login') }} >
                          Back to Login
                        </Button>
                        <Button type="primary" htmlType="submit" size='middle' >
                          Verify
                        </Button>
                      </Row>
                    </Form.Item>
                  </Col>
              </Form>
              </div>       
          )}

          
          {current === 1 &&
          <>
            {/* <h3>Details</h3> */}
            <Form name="basic"
              style={{ paddingTop: 10 }}
              initialValues={{ remember: true, }}
              onFinish={handleAddress}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
               ref={formRef2}
              // form={form}
              >
              {/* <Divider style={{marginTop:0}}/> */}

{ editCompany ?
<>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item name="company_name" label={<div className='label'>Company Name</div>} rules={[{ required: true, message: "Company Name Is Required" }]} >
                    <Input type='text' size="middle" disabled={editDissabled} onChange={(value)=>{handleCompany(value)}}/>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name="legal_name" label={<div className='label'>Legal Name</div>} rules={[{ required: true, message: "Company Name Is Required" }]} >
                    <Input type='text' size="middle" disabled={editDissabled} />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name="trade_name" label={<div className='label'>Trade Name</div>} rules={[{ required: true, message: "Company Name Is Required" }]} >
                    <Input type='text' size="middle" disabled={editDissabled} />
                  </Form.Item>
                </Col>

                <Col span={1} style={{display:'flex',alignItems:'center'}}>
                  <div onClick={()=>{setEditCompany(!editCompany)}}><Tooltip title="Edit"><EditOutlined /></Tooltip></div>
                </Col>
              </Row> 
              
              <Row >

                <Col span={24}>
                  <h4 >Permenent Address : </h4>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="address1" label={<div className='label'>Address1</div>} rules={[{ required: true, message: "Address Is Required" }]} >
                        <Input type='text' size="middle" disabled={editDissabled} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="address2" label={<div className='label'>Address2</div>} >
                        <Input type='text' size="middle" disabled={editDissabled} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="address3" label={<div className='label'>Address3</div>} >
                        <Input type='text' size="middle" disabled={editDissabled} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="country" label={<div className='label'>Country</div>}>
                        <Select size="middle" disabled={editDissabled} onChange={(value)=>{setCountry(value)}}>
                          <Option value="IND">India</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="state" label={<div className='label'>State</div>} >
                        <Select size="middle" disabled={editDissabled} onChange={(value)=>{setState(value)}}>
                        <Option value="TN">Tamilnadu</Option>
                        <Option value="KL">Kerala</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="city" label={<div className='label'>City</div>} >
                        <Input type='text' size="middle" disabled={editDissabled} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="zip" label={<div className='label'>Zip</div>} >
                        <Input type='text' size="middle" disabled={editDissabled} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Row>
                      <Col span={24} style={{display:'flex',justifyContent:'end'}}>
                      <Button size='middle' style={{ width: 100,marginRight: 5 }} onClick={() => { setCurrent(current - 1) }} >
                        Previous
                      </Button>
                      <Button type="primary" htmlType="submit" size='middle'>
                        Next
                      </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>         
</>
             : 
             <Row style={{paddingLeft:20}}>
                    <Col span={24} >
                               <Col span={12} style={{display:'flex',flexDirection:'row'}}>
                                  <Col ><h4>Permanent Address : </h4></Col>
                                    <Col style={{paddingLeft:100,alignItems:'center',display:'flex'}}>
                                      <div onClick={()=>{setEditCompany(!editCompany)}}><Tooltip title="Edit"><EditOutlined /></Tooltip></div>
                                     </Col>
                                </Col>
                                <Col span={12}>
                                   <div style={{fontWeight:'500',fontSize:16}}>{permanentAddress.company_name && permanentAddress.company_name+','}</div>
                                </Col>
                                <Col span={8}>
                                  <div>{combinedValues.details.address1 && combinedValues.details.address1+','}</div>
                                </Col>
                                <Col span={7}>
                                  <div>{combinedValues.details.address2 && combinedValues.details.address2+','}</div>
                                </Col>
                                <Col span={7}>
                                  <div>{combinedValues.details.address3 && combinedValues.details.address3+','}</div>
                                </Col>
                                <Col span={7}>
                                  <div>{country && country+','}</div>
                                </Col>
                                <Col span={7}>
                                  <div>{state && state+','}</div>
                                </Col>
                                <Col span={7}>
                                  <div>{combinedValues.details.zip && combinedValues.details.zip+'.'}</div>
                                </Col>
                                <Col span={24} style={{display:'flex',justifyContent:'end'}}>
                                <Form.Item>
                                      <Button size='middle' style={{ width: 100,marginRight: 5 }} onClick={() => { setCurrent(current - 1) }} >
                                        Previous
                                      </Button>
                                      <Button type="primary" htmlType="submit" size='middle'>
                                        Next
                                      </Button>
                                  </Form.Item>
                              </Col>
                      </Col>


             </Row>   

                             }

            </Form>    
          </>
        }
        {current === 2 &&
          <>
            <Form name="basic"
              style={{ paddingTop: 10 }}
              initialValues={{ remember: true, }}
              onFinish={handleSecondAddress}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical" ref={formRef3}>
              <Col span={24}>
                <h4>Address For Communication :</h4>
                <Row style={{ paddingTop: 10, paddingBottom: 15 }} ><Checkbox   onChange={() => { setCheckbox(!checkbox); setAdress(checkbox); }}checked={checkbox} >Same as Permenent Address</Checkbox></Row>
                <Row gutter={16}>

                  <Col span={12}>
                    <Form.Item name="communication_address1" label={<div className='label'>Address1</div>} rules={[{ required: true, message: "Address Is Required" }]} >
                      <Input type='text' size="middle" disabled={editDissabled} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="communication_address2" label={<div className='label'>Address2</div>} >
                      <Input type='text' size="middle" disabled={editDissabled} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="communication_address3" label={<div className='label'>Address3</div>} >
                      <Input type='text' size="middle" disabled={editDissabled} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="communication_state" label={<div className='label'>State</div>} >
                      <Select size="middle" disabled={editDissabled} >
                        <Option value="TN">Tamilnadu</Option>
                        <Option value="KL">Kerala</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item name="communication_country" label={<div className='label'>Country</div>}>
                      <Select size="middle" disabled={editDissabled} >
                        <Option value="IND">India</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="communication_city" label={<div className='label'>City</div>} >
                      <Input type='text' size="middle" disabled={editDissabled} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item name="communication_zip" label={<div className='label'>Zip</div>} >
                      <Input type='text' size="middle" disabled={editDissabled} />
                    </Form.Item>
                  </Col>

                </Row>
                <Col span={24} align={'end'}>
                  <Form.Item>
                    <Row>
                    <Col span={24} align={'end'}>
                      <Button size='middle' style={{ width: 100,marginRight: 5 }} onClick={() => { setCurrent(current - 1) }} >
                        Previous
                      </Button>
                      <Button type="primary" htmlType="submit" size='middle' >
                        Next
                      </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>

              </Col>
            </Form>
          </>
        }
         {current === 3 &&
          <>
            {/* <h3 style={{fontWeight:'500',marginTop:0}}>Contact Person </h3> */}
            {/* <EditOutlined/> */}
            {/* <Divider style={{marginTop:0}}/> */}
            <Form name="basic"
              style={{ paddingTop: 10 }}
              initialValues={{ remember: true, }}
              onFinish={handleContact}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              ref={formRef4}>
              <Row gutter={16}>

                <Col span={8}>
                  <Form.Item name="first_name" label={<div className='label'>First Name</div>} rules={[{ required: true, message: "First Name Is Required" }]} >
                    <Input type='text' size="middle" disabled={editDissabled} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name="middle_name" label={<div className='label'>Middle Name</div>} >
                    <Input type='text' size="middle" disabled={editDissabled} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name="last_name" label={<div className='label'>Last Name</div>} rules={[{ required: true, message: "Last Name Is Required" }]} >
                    <Input type='text' size="middle" disabled={editDissabled} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                <Form.Item
                      name="email"
                      label={<div className='label'>Email</div>}
                      rules={[
                        { required: true, message: "Email is required" },
                        {
                          type: 'email',
                          message: 'The input is not a valid email address!',
                        },
                        {
                          validator: async (_, value) => {
                            if (value && value.length > 2) {
                              const res = await UserService.findAll({ email: value });
                              if (res && res.length > 0) {
                                throw new Error('Email Already Exists!');
                              }
                            }
                          },
                        },
                      ]}
                    >
                      <Input type='email' size="middle" disabled={editDissabled} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                <Form.Item
                    name="mobile"
                    label={<div className='label'>Mobile</div>}
                    rules={[
                      { required: true, message: "Mobile number is required" }, {
                        len: 10,
                        message: 'Mobile number should be 10 characters long',
                    },
                      {
                        validator: async (_, value) => {
                          if (value && value.length === 10) {
                            const res = await UserService.findAll({ mobile: value });
                            if (res && res.length > 0) {
                              throw new Error('Mobile Already Exists!');
                            }
                          }
                        },
                      },
                    ]}
                  >
                    <Input type='number' size="middle" disabled={editDissabled} />
              </Form.Item>
                </Col>

                <Col span={24} >
                  <Form.Item>
                    <Row>
                      <Col span={24} align={'end'}>
                      <Button size='middle' onClick={() => { setCurrent(current - 1) }} style={{ marginRight: 5, width: 100 }}>
                        Previous
                      </Button>
                      <Button type="primary" htmlType="submit" size='middle' >
                        Submit
                      </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

          </>
        } 
          </div>
          </div>
        
          </div>
          
        {/* </div> */}
        </div>
        </div>
        </div>
        </div>
        </div>
      // </Modal>
  )
}

export default AddSignUp;