import React from 'react'
import HeaderContent from '../components/headerContent.jsx'
import Service from './service.jsx'

import FooterContent from '../components/footerContent.jsx'
import "../website.css";
import serviceimg from "../assets/service1.svg"

const Aboutus = () => {
  const label = "";
  const title = "Top-Notch Automobile Services Driving Your Business Forward with Confidence";
  const matter = "";
  const button = false;
  const bgImage = serviceimg;
  const bgColor = "#1B72D9";

  return (
    <div className="container">
      <HeaderContent
        label={label}
        title={title}
        matter={matter}
        button={button}
        bgImage={bgImage}
        bgColor={bgColor}
      />
    <Service></Service>
    
    
    <FooterContent></FooterContent>
    </div>
  )
}

export default Aboutus