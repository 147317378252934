import React from 'react'
import HeaderContent from '../components/headerContent.jsx'
// import WhoWeAre from './components/WhoWeAre.jsx'
// import WhatWeDo from './components/WhatWeDo.jsx'
import Content from './Components/Contents.jsx'
import FooterContent from '../components/footerContent.jsx'
// import "../website.css";
import contactbg from '../assets/contactus.svg'

const ContactUs = () => {
  const label = "";
  const title = "We Are Waiting To Hear From You";
  const matter = "";
  const button = false;
  const bgImage = contactbg;
  const bgColor = "#1B72D9";

  return (
    <div className="container">
      <HeaderContent
        label={label}
        title={title}
        matter={matter}
        button={button}
        bgImage={bgImage}
        bgColor={bgColor}
      />
    {/* <WhoWeAre></WhoWeAre> */}
    
    {/* <WhatWeDo></WhatWeDo> */}
    <Content></Content>
    <FooterContent></FooterContent>
    </div>
  )
}

export default ContactUs