import React from 'react';
import AuctionfloW from '../../assets/AuctionFloww.svg'
const AuctionFlow = () => {
  return (
    <div style={{display:'flex',justifyContent:'center'}}>
     <img src={AuctionfloW} alt="" className="responsive-image" />
    </div>
  );
};

export default AuctionFlow;
