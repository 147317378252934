import React from 'react';
import VideoPlayer from './VideoPlayer';
import "./VideoPlayer.css"
const SmallGlimbs = () => {
  return (
    <div className='Video-style'>
      <VideoPlayer />
    </div>
  );
};

export default SmallGlimbs;
