import React, { useEffect, useRef, useState } from "react";
import WebCard from "../WebCard";
// import { Pagination } from "antd";
import { Button, Col, Pagination, Row } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import AuctionService from '../../../api/service/Auction/AuctionService';

const Todayauctions = () => {
  const cardsData = Array.from({ length: 10 }, (_, index) => ({
    id: index + 1,
    title: `Card ${index + 1}`,
  }));

  // const [currentPage, setCurrentPage] = useState(1);
  let currentPage = useRef(1);
  let pageSize = 4;

  let startIndex = (currentPage.current - 1) * pageSize;

  const [data, settData] = useState([]);
  const [recentData, setRecenttData] = useState([]);
  const handlePageChange = (page) => {
    // setCurrentPage(page);
  };
  useEffect(() => {
    getRecentData()
  }, [])
  const getRecentData = async () => {
    try {
      let res = await AuctionService.getVehicleListing();
      console.log(res, 'raw response');

      res.forEach(e => {
        try {
          e.d.image_url = JSON.parse(e.d.image_url);
        } catch (error) {
          console.error(`Failed to parse image_url for item with id ${e.id}:`, error);
          e.d.image_url = null; // Or handle in another appropriate way
        }
      });

      console.log(res, 'parsed response');
      res.sort((a, b) => b.id - a.id);
      console.log(res, 'sorted by id in descending order');
      res =res.map(e=>e.d)

      let result = res.slice(startIndex, startIndex + pageSize);
      console.log(result, 'paged result');

      setRecenttData(res);
      settData(result);
    } catch (error) {
      console.error('Failed to fetch recent data:', error);
    }
  };
  // const getRecentData = async () => {

  //   let res = await AuctionService.getVehicleListing();
  //   console.log(res,'res1')
  //   res.forEach(e => e.d.image_url = JSON.parse(e.d.image_url))
  //   let result = res.slice(startIndex, startIndex + pageSize);
  //   res =res.map(e=>e.d)
  //   setRecenttData(res)
  //   settData(result)

  //   console.log(data, 'recentres123datata')
  // }
  // const handlePrev = () => {
  //   if (currentPage.current > 1) {
  //     currentPage.current -= 1;
  //   }
  //   else {
  //     currentPage.current = 1;
  //   }
  //   let pageSize = 4;

  //   let startIndex = (currentPage.current - 1) * pageSize;
  //   let res = JSON.parse(JSON.stringify(recentData))
  //   let result = res.slice(startIndex, startIndex + pageSize);
  //   settData(result)
  // }
  // const handleNext = () => {
  //   let maxPage = recentData.length/4
  //   if (currentPage.current <= maxPage) {
  //     currentPage.current += 1;
  //   }
  //   else {
  //     currentPage.current = 1;
  //   }
  //   let pageSize = 4;

  //   let startIndex = (currentPage.current - 1) * pageSize;
  //   let res = JSON.parse(JSON.stringify(recentData))
  //   let result = res.slice(startIndex, startIndex + pageSize);
  //   settData(result)
  // }
  const handlePrev = () => {
    if (currentPage.current > 1) {
      currentPage.current -= 1;
    } else {
      currentPage.current = 1;
    }
    let startIndex = (currentPage.current - 1) * pageSize;
    let result = recentData.slice(startIndex, startIndex + pageSize);
    settData(result);
  };

  const handleNext = () => {
    let maxPage = Math.ceil(recentData.length / pageSize);
    if (currentPage.current < maxPage) {
      currentPage.current += 1;
    } else {
      currentPage.current = 1;
    }
    let startIndex = (currentPage.current - 1) * pageSize;
    let result = recentData.slice(startIndex, startIndex + pageSize);
    settData(result);
  };
  const itemRender = (_, type, originalElement) => {
    // alert(type)
    if (type === 'prev') {
      // alert('prev')
      //  return <Button style={{height:32,width:32,borderRadius:'50%'}}><LeftOutlined /></Button>
    }
    if (type === 'next') {
      // alert('next')
      // return <Button style={{height:32,width:32,borderRadius:'50%'}}><RightOutlined /></Button>;
    }
    return null;
  };
console.log(data,'datae2323')
  return (
    <>
      {data && data.length > 0 &&  <div className="recent-container">
        <Row gutter={13} style={{ padding: 15 }}>
          <Col span={8}>
            <span className="content-label">Vehicle Listing</span>
          </Col>
          <Col offset={8} span={8} style={{ textAlign: 'end' }}>
            <span style={{ padding: 5 }}><Button onClick={handlePrev} style={{ height: 32, width: 32, borderRadius: '50%' }}><LeftOutlined /></Button></span>
            <Button onClick={handleNext} style={{ height: 32, width: 32, borderRadius: '50%' }}><RightOutlined /></Button>
          </Col>
        </Row>
        {/* <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={cardsData.length}
          onChange={handlePageChange}
        /> */}

        {/* <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={recentData.length}
          onChange={handlePageChange}
          itemRender={itemRender}
        /> */}

{data && data.length > 0 && (
        <div className="cards-grid">
          {data.map((item, index) => (
            <div key={index} className="card-item">
              <WebCard recentData={[item]} showButton={false} />
            </div>
          ))}
        </div>
      )}
       {/* <div className="cards-grid">
       {data.map((item, index) => (
            <div key={index} className="card-item">
              <WebCard recentData={[item]} showButton={false} />
            </div>
          ))}
          {/* <WebCard recentData={data} showButton={true}/> */}
        {/* </div> */} 
      </div>}
    </>
  );
};

export default Todayauctions;
