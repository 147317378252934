import React, { useEffect } from "react";
import { Image, Col, Row } from "antd";
import { Link } from "react-router-dom";
import "../../components/mainc/WebCard.css"; // Ensure to import the CSS file

const WebCard = ({ recentData, showButton }) => {
    useEffect(() => {
        console.log(recentData, 'keycarsdwu77eeuj');
    }, [recentData]);
 
    return (
        <Row style={{ width: "100%" }} gutter={[16, 16]} >
            {recentData.map((key, i) => (
                <Col  xs={24} sm={12} md={8} lg={6} xl={6} key={i}>
                    <div className="card-component">
                        <div className="image-container">
                        {key?.d.image_url.frontview[0] ? (
                            <Image
                                height={210}
                                className="card-image"
                                src={key?.d.image_url.frontview[0]}
                                preview={true} // Disable image preview on click
                            />
                        ) : (
                            <p style={{padding:'84px'}}>No image available</p>
                          )}
                        </div>
                        <div className={`detail-box ${!showButton ? "no-button-box" : ""}`}>
                            <div className="detail-box-inner">
                                <div className="card-title" style={{color:'#566db0',fontWeight:700}}>
                                    {key?.d.make_code}-{key?.d.model_code}
                                </div>
                            </div>
                            <div className="card-button">
           <Link to='https://tvscertified.in/apps/login' target="blank"> <p class="btn-label">Buy</p></Link>
            </div>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

export default WebCard;
