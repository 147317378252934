import React from "react";
import HeaderContent from "../components/headerContent.jsx";
import AcutionStructure from "./components/AcutionStructure.jsx";
import WhyTvsCertified from "./components/WhyTvsCertified.jsx";
import FooterContent from "../components/footerContent.jsx";
import "../website.css";
import auctionstructueBg from '../assets/auctionstructueBg.svg'

const Auctionflow = () => {
  const label = "";
  const title = "Experience the thrill of Bidding!";
  const matter = "";
  const button = false;
  const bgImage = auctionstructueBg;
  const bgColor = "#383739";

  return (
    <div className="container">
      <HeaderContent
        label={label}
        title={title}
        matter={matter}
        button={button}
        bgImage={bgImage}
        bgColor={bgColor}
      />
      <AcutionStructure></AcutionStructure>
      {/* <WhyTvsCertified></WhyTvsCertified> */}
      <FooterContent></FooterContent>
    </div>
  );
};

export default Auctionflow;
