import React, { useEffect, useState } from 'react';
import {Row,Col} from 'antd'
import { useTimer } from 'react-timer-hook';
import AuctionService from '../../../api/service/Auction/AuctionService';
import "./Next_Auction.css"
const NextAuction = () => {
    const [expiryTimestamp, setExpiryTimestamp] = useState(new Date());

    useEffect(() => {
        const getTime = async () => {
            let res = await AuctionService.getNextAuctionTime();
            let auctionTime = new Date(res[0].auction_time);
            console.log(auctionTime,'aucttime')
            const time = new Date(auctionTime);
            time.setSeconds(time.getSeconds());
            setExpiryTimestamp(time);
        };

        getTime();
    }, []);

    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

    useEffect(() => {
        restart(expiryTimestamp);
    }, [expiryTimestamp, restart]);
    const isAuctionSoon = hours === 0 && minutes === 0 && seconds === 0;

    return (
        <> 

        <Row className='Nexxt-Auction-style' gutter={24}>
            <Col className='Nexxt-Auction-style1' span={16}>
            <div className='next-container'>
                <div className='next-content'>
                    
            
                {isAuctionSoon ? (
                                <div className='next-title-No-Time'>
                                    Our Next Auction is coming soon. <br /> Be prepared to place your bids!
                                </div>
                            ) : (<>
                                <div className='next-title'>
                    Next Auction
                </div>
                            
                                
                                <div className='next-clock'>
                                    <div>
                                        <div className='next-number'>{hours}</div>
                                        <div className='next-letter'>Hours</div>
                                    </div>
                                    <div>
                                        <div className='next-number'>{minutes}</div>
                                        <div className='next-letter'>Minutes</div>
                                    </div>
                                    <div>
                                        <div className='next-number'>{seconds}</div>
                                        <div className='next-letter'>Seconds</div>
                                    </div>
                                </div>
                                </>
                            )}
                
            </div>
            </div>
            </Col>
            <Col span={8}>
            <div className="car-container-item">
            <div className="cars-number"> 100+Cars</div>
            <div className="ever-car">
                <div>
                Every car is carefully handpicked after a 
                <br/>
                thorough quality inspection.
                </div>
                </div>
            </div>
            </Col>
        </Row>
                
              
               
              
          
           
           
        </>
        
    );
}

export default NextAuction;
