import React from "react";
import HeaderContent from "./components/headerContent.jsx";
import MainContent from "./components/mainContent.jsx";
import FooterContent from "./components/footerContent.jsx";
// import CarouselComp from "./components/CarouselComp.js";
import "./website.css";
import topBg from "./assets/home-page-carousel-1.png";
import slide2 from "./assets/home-page-carousel-2.png";
import slide3 from "./assets/home-page-carousel-3.png";

const Index = ({ member }) => {
  const label = "";
  const title = "Vehicle Auctions driven by Trust, Value & Transparency ";
  const matter ="More bids and Better Prices";
  const button = member ? true : false;
  const bgImage = topBg;
  const bgColor = "#1B72D9"


  const carouselData = [
    {
      label:"",
      title: "Vehicle Auctions driven by Trust, Value & Transparency ",
      button: member ? true : false,
      bgImage: topBg,
      bgColor: "#1B72D9"

    },
    {
      label: "",
      title: "Confidence in Every Inspection Your Trusted Vehicle Auction Partner",
      matter: "",
      button: member ? true : false,
      bgImage: slide2,
      bgColor: "#313D82",
    },
    {
      label: "",
      title: "Explore Our Vehicle Listings, Improve Your Selections",
      matter: "",
      button: member ? true : false,
      bgImage: slide3,
      bgColor: "#06756E",
    },
  ];

  return (
    <div className="container">
      <HeaderContent
        label={label}
        title={title}
        matter={matter}
        button={button}
        bgImage={bgImage}
        bgColor={bgColor}
        carouselData={carouselData}
      />
      {/* <CarouselComp arrayComp={arrayComp} /> */}
      <MainContent></MainContent>
      <FooterContent></FooterContent>
    </div>
  );
};

export default Index;
