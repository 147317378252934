import React from 'react';

const HeaderContent = ({ label, title, matter, button }) => {

  return (
    <div className='header-container'>
      <div className='header-label'>{label}</div>
      <div className='header-title'>{title}</div>
      <div className='header-matter'>{matter}</div>
      {button && <button className='header-button'>List your Car</button>}
    </div>
  );
}

export default HeaderContent;
